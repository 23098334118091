angular.module("app")
	.controller("resetPasswordController", ["$log", "$state", "$stateParams", "$location", "$window", "Notification", "userService", function($log, $state, $stateParams, $location, $window, Notification, userService){
		var ctrl = this;
		var activation = $stateParams.activation;
		ctrl.running = false;
		ctrl.messages = [];
        ctrl.showPassword = false;
		ctrl.password = null;

        ctrl.toggleShowPassword = function() {
            ctrl.showPassword = !ctrl.showPassword;
        };

		ctrl.submit = function(password){
            if(!validatePassword(password)) {
                Notification.error({message: "Password entered does not meet the required criteria"});
                return;
			}

			ctrl.running = true;
			userService.resetPassword(activation, password)
			 	.then(function(result){
		 			 $window.location="/dashboard/";
			 	})
			 	.catch(function(resp){
			 		if(resp.data.messages){
			 			ctrl.messages = resp.data.messages;
			 		}else {
			 			Notification.error({message: "Failed to reset your password, please try again", delay: 5000});
			 		}
			 	})
			 	.finally(function(){
			 		ctrl.running = false;
			 	});
		};

        function validatePassword(pw) {
            var criteria = 0;
            if (pw.toUpperCase() != pw) {
                // has lower case letters
                criteria++;
            }
            if (pw.toLowerCase() != pw) {
                // has upper case letters
                criteria++;
            }
            if (/^[a-zA-Z0-9]*$/.test(pw) === false) {
                // has special characters
                criteria++;
            }
            if (/\d/.test(pw) === true) {
                // has numbers
                criteria++;
            }
            // returns true if 3 or more criteria was met and length is appropriate
            return (criteria >= 3 && pw.length >= 10 && pw.length <= 128);
        }


	}]);